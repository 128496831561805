import type { MetaFunction } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { clsx } from "clsx";

import imageLaptop from "~/assets/laptop.jpg";
import { ContactSection } from "~/components/contact-section";
import { Container } from "~/components/container";
import { FadeIn, FadeInStagger } from "~/components/fade-in";
import { List, ListItem } from "~/components/list";
import { SectionIntro } from "~/components/section-intro";
import { StylizedImage } from "~/components/stylized-image";
import { Svg } from "~/components/svg";
import { Testimonial } from "~/components/testimonial";
import { clientsLight, testimonials } from "~/lib/client.server";
import type { MDXCaseStudy } from "~/lib/mdx.server";
import { loadCaseStudies } from "~/lib/mdx.server";

export const meta: MetaFunction = () => {
  return [
    {
      title: `bmcreations - Mobile app consultancy turning ideas into reality in the USA`,
    },
    {
      name: "description",
      content: `We are a mobile app consultancy turning ideas into reality in the USA.`,
    },
  ];
};

function Clients() {
  let data = useLoaderData<typeof loader>();

  return (
    <Container>
      <div className="-mx-6 mt-24 max-w-7xl rounded-4xl bg-neutral-950 py-20 sm:mt-32 sm:py-32 lg:mt-56">
        <FadeIn className="flex items-center gap-x-8 px-6 sm:mx-0 lg:px-8">
          <h2 className="text-center font-display text-sm font-semibold tracking-wider text-white sm:text-left">
            We&apos;ve worked with some amazing people
          </h2>
          <div className="h-px flex-auto bg-neutral-800" />
        </FadeIn>

        {data.clients.map((clientsArray, index) => {
          return (
            <div
              className="mt-10 flex select-none gap-8 overflow-hidden"
              key={index}
            >
              {clientsArray.map((clients, clientsIndex) => (
                <ul
                  key={clientsIndex}
                  className={clsx(
                    "flex min-w-full flex-shrink-0 gap-8",
                    index % 2 === 0
                      ? "animate-scroll-reverse"
                      : "animate-scroll",
                  )}
                >
                  {clients.map(([client, logo, size]) => {
                    return (
                      <li
                        key={client}
                        className="flex items-center justify-center"
                      >
                        <FadeIn>
                          <Svg
                            name={logo}
                            aria-label={client}
                            className="object-contain"
                            width={size.width}
                            height={size.height}
                          />
                        </FadeIn>
                      </li>
                    );
                  })}
                </ul>
              ))}
            </div>
          );
        })}
      </div>
    </Container>
  );
}

function CaseStudies({ caseStudies }: { caseStudies: Array<MDXCaseStudy> }) {
  return (
    <>
      <SectionIntro
        title="Harnessing technology for a brighter future"
        className="mt-24 sm:mt-32 lg:mt-40"
      >
        <p>
          We believe technology is the answer to the world’s greatest
          challenges. It’s also the cause, so we find ourselves in bit of a
          catch 22 situation.
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <FadeInStagger className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {caseStudies.map((caseStudy) => {
            return (
              <FadeIn key={caseStudy.href} className="flex">
                <article className="relative flex w-full flex-col rounded-3xl p-6 ring-1 ring-neutral-950/5 transition hover:bg-neutral-50 sm:p-8">
                  <h3>
                    <Link to={caseStudy.href}>
                      <span className="absolute inset-0 rounded-3xl" />
                      <img
                        src={caseStudy.logo}
                        alt={caseStudy.client}
                        className="h-16 w-16"
                      />
                    </Link>
                  </h3>
                  <p className="mt-6 flex gap-x-2 text-sm text-neutral-950">
                    <time
                      dateTime={caseStudy.date.split("-")[0]}
                      className="font-semibold"
                    >
                      {caseStudy.date.split("-")[0]}
                    </time>
                    <span className="text-neutral-300" aria-hidden="true">
                      /
                    </span>
                    <span>Case study</span>
                  </p>
                  <p className="mt-6 font-display text-2xl font-semibold text-neutral-950">
                    {caseStudy.title}
                  </p>
                  <p className="mt-4 text-base text-neutral-600">
                    {caseStudy.description}
                  </p>
                </article>
              </FadeIn>
            );
          })}
        </FadeInStagger>
      </Container>
    </>
  );
}

function Services() {
  return (
    <>
      <SectionIntro
        eyebrow="Services"
        title="We help you identify, explore and respond to new opportunities."
        className="mt-24 sm:mt-32 lg:mt-40"
      />
      <Container className="mt-16">
        <div className="lg:flex lg:items-center lg:justify-end">
          <div className="flex justify-center lg:w-1/2 lg:justify-end lg:pr-12">
            <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
              <StylizedImage
                src={imageLaptop}
                sizes="(min-width: 1024px) 41rem, 31rem"
                className="justify-center lg:justify-end"
              />
            </FadeIn>
          </div>
          <List className="mt-16 lg:mt-0 lg:w-1/2 lg:min-w-[33rem] lg:pl-4">
            <ListItem title="Mobile Application Development">
              We are at the forefront of modern app development, and will
              develop a mobile app that will flourish in the palm of your hand.
            </ListItem>
            <ListItem title="UI/UX Design">
              We specialize in crafting, beautiful, high quality mobile app
              designs using the latest standards and expertise.
            </ListItem>
          </List>
        </div>
      </Container>
    </>
  );
}

export async function loader() {
  let caseStudies = await loadCaseStudies();
  let reversedClients = clientsLight.toReversed();
  return {
    caseStudies: caseStudies.slice(0, 3),
    testimonial: {
      client: { name: "Gabb", logo: "client-gabb-logo-dark" },
      message: testimonials["Gabb"],
    },
    clients: [
      [reversedClients, reversedClients],
      [clientsLight, clientsLight],
    ],
  } as const;
}

export default function Home() {
  let data = useLoaderData<typeof loader>();

  return (
    <>
      <Container className="mt-24 sm:mt-32 md:mt-56">
        <FadeIn className="max-w-3xl">
          <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
            Mobile app consultancy turning ideas into reality in the USA
          </h1>
          <p className="mt-6 text-xl text-neutral-600">
            Where design and technology meet to create exceptional apps. We
            deliver innovative solutions that make your business soar.
          </p>
        </FadeIn>
      </Container>

      <Clients />

      <CaseStudies caseStudies={data.caseStudies} />

      <Testimonial
        className="mt-24 sm:mt-32 lg:mt-40"
        client={data.testimonial.client}
      >
        {data.testimonial.message}
      </Testimonial>

      <Services />

      <ContactSection />
    </>
  );
}
